<template>
    <Toolbar>
        <template v-slot:right>
            <Button label="Filter" icon="pi pi-filter-slash" @click="filterlisting()"></Button>
        </template>
    </Toolbar>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="newTicketList" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="sa1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">

                    <template #empty> No data found. </template>

                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 5%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column header="Client Information" headerStyle="width: 15%">

                        <template #body="{ data }">
                            <div>{{ data.sa3 ? data.sa3 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Ticket Details" headerStyle="width: 45%">

                        <template #body="{ data }">
                            <div>
                                <div class="p-mb-1"><span class="p-text-bold">Ticket Created :</span> {{ data.sa12 ?
                                    format_timestamp(data.sa12) : 'N/A' }}</div>
                                <div class="p-mb-1">
                                    by <span class="p-text-capitalize">{{ data.sa31 ? data.sa31 : 'N/A' }}</span>
                                </div>
                                <div class="p-d-flex p-dir-row p-ai-center"><Button icon="pi pi-phone"
                                        v-tooltip.left="'Click To Call'" class="ticket-custom-call-btn p-button-success"
                                        @click="triggerCallToMeraNetaClients(data)"
                                        v-if="data.mbk6 != '' && data.mbk6 != null" :disabled="isDisabled">
                                    </Button>{{ data.mbk6 ? '+91' + data.mbk6 : '' }}
                                </div>
                            </div>
                            <Divider type="dashed" style="margin: 2% 0%;" />
                            <div>
                                <div class="p-mb-1 p-d-flex p-jc-between">
                                    <span>
                                        <span class="p-text-bold">Ticket Number : </span><span class="indigo-color">{{
                                            data.sa4 ? data.sa4 : 'N/A' }}</span>
                                    </span>
                                    <span>
                                        <span class="p-text-bold">Type: </span>
                                        <span v-if="data.sa38 == 1" :class="'user-badge status-active'">Voter</span>
                                        <span v-else-if="data.sa38 == 2"
                                            :class="'user-badge status-temp-suspend'">User</span>
                                        <span v-else :class="'user-badge status-temp-suspend'">N/A</span>
                                    </span>

                                </div>
                                <Divider type="dashed" style="margin: 2% 0%;" />

                                <div class="p-d-flex p-jc-between">
                                    <span>
                                        <span class="p-text-bold">Facing Problem In : </span><span
                                            v-if="data.sa5 == 1">Web
                                            Application</span>
                                        <span v-if="data.sa5 == 2">Android Application</span>
                                        <span v-if="data.sa5 == 3">IOS Application</span>
                                        <span v-if="data.sa5 == 4">All</span>
                                        <span v-if="data.sa5 == '' || data.sa5 == null">N/A</span>
                                    </span>
                                    <span>
                                        <span class="p-text-bold custom-status">Status: </span>
                                        <span v-if="data.sa18 == 1" :class="'user-badge status-active'">New</span>
                                        <span v-if="data.sa18 == 2" :class="'user-badge status-active'">Open</span>
                                        <span v-if="data.sa18 == 3"
                                            :class="'user-badge status-temp-suspend'">Closed</span>
                                        <span v-if="data.sa18 == 4"
                                            :class="'user-badge status-per-suspend'">Cancelled</span>
                                        <span v-if="data.sa18 == '' || data.sa18 == null">N/A</span>
                                    </span>
                                </div>
                            </div>
                            <Divider type="dashed" style="margin: 2% 0%;" />

                            <div v-if="data.sb5 != null"><span class="p-text-bold">Description : </span>
                                <span v-if="!activate_read_key_arr.includes(data.sa1)"
                                    style="word-wrap: break-word; white-space: normal;">
                                    {{ data.sb5.slice(0, 140).replaceAll('*', '') }}
                                </span>
                                <a v-if="data.sb5.length >= 140 && !activate_read_key_arr.includes(data.sa1)"
                                    @click="activateReadMore(data.sa1)" class="showmore p-ml-2">read more</a>
                                <span v-if="activate_read_key_arr.includes(data.sa1)"
                                    v-html="data.sb5.replaceAll('*', '')"
                                    style="word-wrap: break-word; white-space: normal;"></span>
                                <span>
                                    <a v-if="data.sb5.length >= 140 && activate_read_key_arr.includes(data.sa1)"
                                        @click="deactivateReadMore(data.sa1)" class="showless p-ml-2"
                                        style="word-wrap: break-word; white-space: normal;">read less</a>
                                </span>
                            </div>
                            <div v-else><span class="p-text-bold">Description : </span>N/A</div>
                            <Divider type="dashed" style="margin: 2% 0%;" />

                            <!-- <Divider type="dashed" style="margin: 2% 0%;" /> -->

                            <div>
                                <div class="p-mb-1"><span class="p-text-bold">Effected URL : </span>{{ data.sa22 ?
                                    data.sa22 : 'N/A' }}
                                </div>
                                <span class="p-text-bold">Effected User : </span><span class="p-text-capitalize">{{
                                    data.sa24 ? data.sa24 : 'N/A' }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Support Action" headerStyle="width: 35%">
                        <template #body="{ data }">
                            <div class="p-mb-1">
                                <span class="p-text-bold">Name: </span>
                                {{ data.sa40 ? JSON.parse(data.sa40).name : 'N/A' }}
                            </div>
                            <div class="p-mb-1">
                                <span class="p-text-bold">Model: </span>
                                {{ data.sa40 ? JSON.parse(data.sa40).model : 'N/A' }}
                            </div>
                            <Divider type="dashed" style="margin: 2% 2%;" />
                            <div class="p-d-flex p-jc-between">
                                <!-- <Divider type="dashed" style="margin: 2% 0%;" /> -->
                                <div>
                                    <span class="p-text-bold">Ticket Type : </span>
                                    <span v-if="data.sa6 == 1">Technical</span>
                                    <span v-if="data.sa6 == 2">Logical/Navigational</span>
                                    <span v-if="data.sa6 == '' || data.sa6 == null">N/A</span>
                                </div>
                                <div><Button label="Show History" severity="info" @click="showHistoryDialogOpen(data)"
                                        class="p-button-text p-py-0" />
                                </div>
                            </div>
                            <div v-if="data.sa38 == 2">
                                <Divider type="dashed" style="margin: 2% 2%;" />
                                <div class="p-text-right"><Button label="Login as Client" severity="info"
                                        class="p-button-text p-ml-auto" @click="loginAsClientDialogOpen(data)" /></div>
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- show history dialog start here -->
    <div class="modal-mask">
        <Dialog v-model:visible="showHistoryDialogStatus" :style="{ width: '1000px' }" header="Ticket History"
            :modal="true" class="p-fluid">
            <div class="card custom-ticket-header" ref="chatBoxElement">
                <Toast />
                <div class="p-pt-2 p-px-3">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-4 p-mb-0">
                            <div><span class="p-text-bold">Client Name :</span> <span class="p-text-capitalize"> {{
                                this.ticketHistoryData.sa3 ? this.ticketHistoryData.sa3 : 'N/A' }}</span> </div>
                            <!-- <div><span class="p-text-bold">Legal Name :</span> Roadland Reality Private Limited</div> -->
                        </div>
                        <div class="p-field p-col-12 p-md-4 p-mb-0">
                            <div><span class="p-text-bold">Ticket No :</span> {{ this.ticketHistoryData.sa4 ?
                                this.ticketHistoryData.sa4 : 'N/A' }} </div>
                        </div>
                        <div class="p-field p-col-12 p-md-4 p-mb-0">
                            <div class="p-mb-1"><span class="p-text-bold">Ticket By :</span> <span
                                    class="p-text-capitalize">{{
                                        this.ticketHistoryData.sa31 ? this.ticketHistoryData.sa31 : 'N/A' }}</span>
                            </div>
                            <div v-if="this.ticketHistoryData.mbk6 != '' && this.ticketHistoryData.mbk6 != null"
                                class="p-d-flex p-dir-row p-ai-center"><Button icon="pi pi-phone"
                                    class="ticket-custom-call-btn p-button-success"
                                    @click="triggerCallToMeraNetaClients(this.ticketHistoryData)"
                                    :disabled="isDisabled">
                                </Button>+91{{ this.ticketHistoryData.mbk6 ? this.ticketHistoryData.mbk6 : 'N/A'
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ticket-chat-box-outer">
                <div class="history-conversation-inner">
                    <div v-for="msg in ticketMessage" :key="msg">
                        <div class="msg-body-align-left" v-if="msg.sb12 == 1">
                            <div class="p-d-flex p-jc-between custom-datetime-label">
                                <span class="p-text-bold p-text-capitalize">{{ msg.sb11 ? msg.sb11 : 'N/A' }}</span>
                                <span class="msg-datetime-left">{{ msg.z501 ? format_timestamp(msg.z501) : 'N/A'
                                    }}</span>
                            </div>
                            <span class="image-profile-outer-left"><img
                                    v-if="msg.profileimageurl == null || msg.profileimageurl == ''"
                                    src="/assets/layout/images/widgets/asset-profile-11.png" class="custom-profile-chat"
                                    alt="" width="32" height="32" />
                                <img v-if="msg.profileimageurl != null && msg.profileimageurl != ''"
                                    class="custom-profile-chat" :src="msg.profileimageurl" alt="" width="32"
                                    height="32" />
                            </span>
                            <div class="message-body-left-arrow">
                                <div class="left-arrow-outer"></div>
                                <div class="left-arrow-inner"></div>
                            </div>
                            <div class="message-body-left">
                                <p><span>{{ msg.sb5 ? msg.sb5 : 'N/A' }}</span>
                                </p>
                            </div>

                            <div v-if="msg.sb6 != '' && msg.sb6 != null">
                                <span class="attachment-left"><i class="pi pi-paperclip me-1"></i>Attachment's : </span>
                                <span v-for="(value, key) in splitJoin(msg.sb6)" :key="key">
                                    <img :src="value" alt="" width="32" height="32" class="attach-file"
                                        @click="previewImageModalOpen(value)" />
                                </span>
                            </div>

                        </div>

                        <div class="msg-body-align-right" v-if="msg.sb12 == 2">
                            <div class="p-d-flex p-jc-between custom-datetime-label">
                                <span class="msg-datetime-right">{{ msg.z501 ? format_timestamp(msg.z501) : 'N/A'
                                    }}</span>
                                <span class="p-text-bold"><span class="p-text-capitalize">{{ msg.sb11 ? msg.sb11 : 'N/A'
                                        }}</span></span>
                            </div>
                            <div>
                                <span class="image-profile-outer-right"><img
                                        v-if="msg.profileimageurl == null || msg.profileimageurl == ''"
                                        src="/assets/layout/images/widgets/asset-profile-11.png"
                                        class="custom-profile-chat" alt="" width="32" height="32" />
                                    <img v-if="msg.profileimageurl != null && msg.profileimageurl != ''"
                                        :src="msg.profileimageurl" class="custom-profile-chat" alt="" width="32"
                                        height="32" />
                                </span>
                                <div class="message-body-right-arrow">
                                    <div class="right-arrow-outer"></div>
                                    <div class="right-arrow-inner"></div>
                                </div>
                                <div class="message-body-right">
                                    <p><span>{{ msg.sb5 ? msg.sb5 : 'N/A' }}</span>
                                    </p>
                                </div>
                            </div>
                            <div v-if="msg.sb6 != '' && msg.sb6 != null" class="attachment-right-outer">
                                <span class="attachment-right"><i class="pi pi-paperclip me-1"></i>Attachment's :
                                </span>
                                <span v-for="(value, key) in splitJoin(msg.sb6)" :key="key">
                                    <img :src="value" alt="" width="32" height="32" class="attach-file"
                                        @click="previewImageModalOpen(value)" />
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="p-pt-2 p-px-3">
                    <div class="custom-heading-ticket-comm">
                        <i class="pi pi-user"></i>
                        <span class="font-bold white-space-nowrap"> Ticket Communication</span>
                    </div>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="ticketstatus">Ticket Status<span class="p-invalid p-error">
                                    *</span></label>
                            <Dropdown id="ticketstatus" v-model="sendmessage.ticketstatus" :options="TicketStatusList"
                                optionLabel="label" placeholder="Select a status" appendTo="body">
                            </Dropdown>
                            <small class="p-invalid p-error" v-if="v$.sendmessage.ticketstatus.$error">{{
                                v$.sendmessage.ticketstatus.$errors[0].$message }}</small>
                        </div>
                        <div class="p-field p-col-6">
                            <label for="details">Tentative Closing Date</label>
                            <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true"
                                v-model="sendmessage.tentativeclosingdate" :minDate="mindate" dateFormat="dd/mm/yy"
                                placeholder="DD/MM/YYYY" appendTo="body" :manualInput="false" showTime hourFormat="12">
                            </Calendar>
                            <!-- <small class="p-invalid p-error" v-if="state.v$.sendmessage.tentativeclosingdate.$error">{{
                                        state.v$.sendmessage.tentativeclosingdate.$errors[0].$message }}</small> -->
                        </div>
                    </div>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label>Third Party Dependency</label>
                            <div class="p-field-radiobutton">
                                <RadioButton id="yes" name="option" v-bind:value="1"
                                    v-model="sendmessage.thirdpartydependency"
                                    :checked="sendmessage.thirdpartydependency == 1" />
                                <label for="yes" class="p-mr-2">Yes</label>
                                <RadioButton id="no" name="option" v-bind:value="0"
                                    v-model="sendmessage.thirdpartydependency"
                                    :checked="sendmessage.thirdpartydependency == 0" />
                                <label for="no" class="p-mr-2">No</label>
                            </div>
                            <!-- <small class="p-invalid p-error"
                                        v-if="state.v$.sendmessage.thirdpartydependency.$error">{{ state.v$.sendmessage.thirdpartydependency.$errors[0].$message }}</small> -->
                        </div>
                        <div class="p-field p-col-6">
                            <label for="ticketassignto">Ticket Assign to<span class="p-invalid p-error">
                                    *</span></label>
                            <Dropdown id="ticketassignto" v-model="sendmessage.ticketassignto" :options="ActiveUserList"
                                optionLabel="label" placeholder="Select User" appendTo="body">
                            </Dropdown>
                            <small class="p-invalid p-error" v-if="v$.sendmessage.ticketassignto.$error">{{
                                v$.sendmessage.ticketassignto.$errors[0].$message }}</small>
                        </div>
                    </div>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="tickettype">Ticket Type</label>
                            <Dropdown id="tickettype" v-model="sendmessage.tickettype" :options="TicketTypeList"
                                optionLabel="label" placeholder="Select a status" appendTo="body">
                            </Dropdown>
                            <!-- <small class="p-invalid p-error" v-if="state.v$.sendmessage.tickettype.$error">{{
                                        state.v$.sendmessage.tickettype.$errors[0].$message }}</small> -->
                        </div>
                    </div>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-12">
                            <label for="message">Message<span class="p-invalid p-error">
                                    *</span></label>
                            <Textarea id="message" v-model="sendmessage.responsemessage" :autoResize="true"
                                maxlength="500" rows="3" cols="30" required="true" autofocus
                                :class="{ 'p-invalid': submitted && !sendmessage.responsemessage }" />
                            <small class="p-invalid p-error" v-if="v$.sendmessage.responsemessage.$error">{{
                                v$.sendmessage.responsemessage.$errors[0].$message }}</small>
                        </div>
                    </div>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-12">
                            <label for="attachfile">Attach Files</label>

                            <!-- <FileUpload :showUploadButton="false" mode="advanced" ref="attach_file" @clear="clearFiles" @remove="removeFile" v-model="uploadfile"
                                aria-describedby="upload-limit" :multiple="true" accept="image/*" :auto="false"
                                :fileLimit="5" v-on:change="onSelectAttachedFile()">
                            </FileUpload> -->
                            <div class="fileUpload">
                                <input type="file" multiple class="upload" id="attach_file" ref="attach_file"
                                    v-on:change="onSelectAttachedFile()" />
                                <span>+ Upload</span>
                            </div>
                            <div style="color:rgb(186, 15, 15);margin-top: 3px;" v-if="fileerror">
                                {{ fileerror }}
                            </div>
                        </div>
                    </div>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-3" v-if="filecount != ''">
                            <i class="pi pi-images" style="font-size: 0.8rem"></i> You have <span>{{
                                this.filecount }}</span>
                            file<span v-if="this.filecount >= 2">s</span> selected.
                            <span title="Clear Attched Files" class="custom-outline-delete-btn ms-2"
                                @click="clearticketscreenimages()" v-if="filecount != ''">
                                <i class="pi pi-trash delete-btn-color"></i>
                            </span>
                        </div>
                    </div>
                    <div class="p-flex p-flex-row">
                        <div class="img-sepration" v-if="attachfileURL1 != null && attachfileURL1 != ''">
                            <img :src="attachfileURL1" class="ticketopenscreen"
                                @click="previewImageModalOpen(attachfileURL1)" width="145" height="150" />
                            <i class="pi pi-times-circle"
                                :style="{ 'marginTop': '-6px', 'marginLeft': '-8px', 'cursor': 'pointer' }"
                                @click="clearAttachfile1()"></i>
                        </div>
                        <div class="img-sepration" v-if="attachfileURL2 != null && attachfileURL2 != ''">
                            <img :src="attachfileURL2" class="ticketopenscreen"
                                @click="previewImageModalOpen(attachfileURL2)" width="145" height="150" />
                            <i class="pi pi-times-circle"
                                :style="{ 'marginTop': '-6px', 'marginLeft': '-8px', 'cursor': 'pointer' }"
                                @click="clearAttachfile2()"></i>
                        </div>
                        <div class="img-sepration" v-if="attachfileURL3 != null && attachfileURL3 != ''">
                            <img :src="attachfileURL3" class="ticketopenscreen"
                                @click="previewImageModalOpen(attachfileURL3)" width="145" height="150" />
                            <i class="pi pi-times-circle"
                                :style="{ 'marginTop': '-6px', 'marginLeft': '-8px', 'cursor': 'pointer' }"
                                @click="clearAttachfile3()"></i>
                        </div>
                        <div class="img-sepration" v-if="attachfileURL4 != null && attachfileURL4 != ''">
                            <img :src="attachfileURL4" class="ticketopenscreen"
                                @click="previewImageModalOpen(attachfileURL4)" width="145" height="150" />
                            <i class="pi pi-times-circle"
                                :style="{ 'marginTop': '-6px', 'marginLeft': '-8px', 'cursor': 'pointer' }"
                                @click="clearAttachfile4()"></i>
                        </div>
                        <div class="img-sepration" v-if="attachfileURL5 != null && attachfileURL5 != ''">
                            <img :src="attachfileURL5" class="ticketopenscreen"
                                @click="previewImageModalOpen(attachfileURL5)" width="145" height="150" />
                            <i class="pi pi-times-circle"
                                :style="{ 'marginTop': '-6px', 'marginLeft': '-8px', 'cursor': 'pointer' }"
                                @click="clearAttachfile5()"></i>
                        </div>
                    </div>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-12 p-text-right">
                            <Button v-if="!showloader" label="Submit" class="p-ml-auto" style="width: 90px"
                                @click="sendMessage()"></Button>
                            <Button v-show="showloader" icon="pi pi-spin pi-spinner indigo-color"
                                class="p-button-text p-ml-auto" style="width: 90px"></Button>
                        </div>
                    </div>
                </div>
            </div>

            <template #footer>
                <div>

                </div>
            </template>
        </Dialog>
    </div>
    <!-- show history dialog end here -->

    <!-- Preview screen images start here -->
    <Dialog v-model:visible="imagePreviewDialog" :style="{ width: '1000px' }" header="Preview" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <img :src="imgsourcepath" style="vertical-align: middle; width: 100%;" />
                </div>
            </div>
        </div>
    </Dialog>
    <!-- Preview screen images end here -->

    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterListingDialog" :style="{ width: '600px' }" header="Filter" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="client">Client</label>
                    <MultiSelect v-model="filterListing.client_fks" :options="clientList" optionValue="value"
                        optionLabel="label" placeholder="Select Client" :filter="true" class="multiselect-custom"
                        appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="didnumber">
                        Ticket Number
                    </label>
                    <InputText id="ticketnumber" v-model.trim="filterListing.ticketnumber" maxlength="8"
                        @keypress="onlyNumber" />
                </div>
                <div class="p-field p-col-12">
                    <label for="fromdate">Ticket Created Date</label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true"
                        v-model="filterListing.createdDate" :maxDate="todaysDate" placeholder="DD/MM/YYYY"
                        dateFormat="dd/mm/yy" :yearRange="'2020:' + new Date().getFullYear()" appendTo="body"
                        required="true" :manualInput="false"
                        :class="{ 'p-invalid': submitted && !filterListing.createdDate }"></Calendar>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(filterListing.client_fks, filterListing.ticketnumber, filterListing.createdDate)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->

    <!--Login as client Dialog starts here -->
    <Dialog v-model:visible="loginAsClientDialogStatus" :style="{ width: '70vw' }" modal class="p-fluid"
        header="Client Login">
        <Toast />
        <div style="position: relative;">
            <div class="p-pt-2 p-px-5">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-d-flex p-field p-col-6 p-md-12">
                        <h6 class="p-m-0 ">Select Employee:</h6>
                        <Dropdown id="select-emp" class="p-ml-5 p-text-capitalize" v-model="clientEmployees"
                            :options="clientEmplist" optionLabel="label" placeholder="Select Employee"
                            emptyMessage="No Records" appendTo="body" style="width: 30vw" />
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button v-if="!showLoader" :disabled="clientEmployees == ''" label="Login" icon="pi pi-check"
                class="p-button-text p-ml-auto" style="width: 90px;" @click="loginAsClientRequest"></Button>
            <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>

    <!-- Login as client Dialog end here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            page_no: 0,
            totalRecords: 0,
            loading: false,
            showHistoryDialogStatus: false,
            filterListingDialog: false,
            newTicketList: [],
            submitted: false,
            showFillFilter: false,
            sendmessage: {
                ticketstatus: '',
                tentativeclosingdate: '',
                thirdpartydependency: '',
                ticketassignto: '',
                tickettype: '',
                responsemessage: '',
            },

            TicketStatusList: [
                { value: "2", label: "Open" },
            ],
            mindate: new Date(),
            TicketTypeList: [
                { value: "1", label: "Technical" },
                { value: "2", label: "Logical (Navigational)" },
            ],
            fileerror: '',
            attachfile1: null,
            attachfileURL1: null,
            attachfile2: null,
            attachfileURL2: null,
            attachfile3: null,
            attachfileURL3: null,
            attachfile4: null,
            attachfileURL4: null,
            attachfile5: null,
            attachfileURL5: null,
            filecount: '',
            uploadfile: [],
            ticketHistoryData: '',
            ticketMessage: '',
            ActiveUserList: [],
            showloader: false,
            imagePreviewDialog: false,
            imgsourcepath: null,
            clientList: [],
            filterListing: {
                client_fks: [],
                ticketnumber: '',
                createdDate: null
            },
            tentativeclosingtimeinhours: '',
            isDisabled: false,
            clientEmplist: [],
            loginAsClientDialogStatus: false,
            client_id: '',
            loginsystemurl: '',
            clientEmployees: '',
            meraneta_url: '',
            showLoader: false,
            todaysDate: new Date(),
            activate_read_key_arr: [],
        };
    },
    watch: {
        sendmessage: {
            handler(val) {
                if (val.tentativeclosingdate != '' && val.tentativeclosingdate != null) {
                    var now = new Date();
                    var endDate = new Date(val.tentativeclosingdate);
                    var diff = endDate - now;

                    var hours = Math.floor(diff / 3.6e6);
                    var minutes = Math.floor((diff % 3.6e6) / 6e4);
                    this.tentativeclosingtimeinhours = hours + ':' + minutes;
                    this.tentativeclosingtimeinhours;
                }
            },
            deep: true
        },
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        if (localStorage.user) {
            var localdata = JSON.parse(localStorage.user);
            this.user_full_name = localdata.user_full_name;
            this.user_id = localdata.user_id;
        }
        this.loading = true;
        this.getTicketsByStatus({ page_no: this.page_no, status: 1 });
    },
    computed: {
        // convertJson(data, field) {
        //     const namedata = JSON.parse(data);
        //     return namedata[field];
        // }
    },

    validations() {
        return {
            sendmessage: {
                ticketstatus: { required: helpers.withMessage('Please Select Ticket Status', required) },
                ticketassignto: { required: helpers.withMessage('Please Select Ticket Assign To', required) },
                responsemessage: { required: helpers.withMessage('Please Enter Message', required) },
            },
        };
    },
    methods: {
        activateReadMore(e) {
            this.activate_read_key_arr.push(e);
        },

        deactivateReadMore(e) {
            var index = this.activate_read_key_arr.indexOf(e);
            if (index !== -1) {
                this.activate_read_key_arr.splice(index, 1);
            }
        },
        loginAsClientDialogOpen(data) {
            this.submitted = false;
            this.clientEmplist = [];
            this.loginAsClientDialogStatus = true;
            this.client_id = data.sa2;
            this.loginsystemurl = data.maa25;
            this.v$.$reset();
            this.getClientUsersOnly();
        },
        getClientUsersOnly() {
            this.ApiService.getClientUsersOnly({ clientId: this.client_id }).then((data) => {
                if (data.success === true) {
                    this.clientEmplist = data.data;
                }
            });
        },
        clearticketscreenimages() {
            this.attachfile1 = null;
            this.attachfile2 = null;
            this.attachfile3 = null;
            this.attachfile4 = null;
            this.attachfile5 = null;
            this.attachfileURL1 = null;
            this.attachfileURL2 = null;
            this.attachfileURL3 = null;
            this.attachfileURL4 = null;
            this.attachfileURL5 = null;
            this.filecount = '';
        },
        clearAttachfile1() {
            this.attachfile1 = null;
            this.attachfileURL1 = null;
            this.filecount--;
        },
        clearAttachfile2() {
            this.attachfile2 = null;
            this.attachfileURL2 = null;
            this.filecount--;
        },
        clearAttachfile3() {
            this.attachfile3 = null;
            this.attachfileURL3 = null;
            this.filecount--;
        },
        clearAttachfile4() {
            this.attachfile4 = null;
            this.attachfileURL4 = null;
            this.filecount--;
        },
        clearAttachfile5() {
            this.attachfile5 = null;
            this.attachfileURL5 = null;
            this.filecount--;
        },
        splitJoin(theText) {
            return theText.split(',');
        },
        previewImageModalOpen(e) {
            this.imagePreviewDialog = true;
            this.imgsourcepath = e;
        },
        onSelectAttachedFile() {
            var file = this.$refs.attach_file.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.fileerror = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.fileerror = 'Invalid file type';
            } else if (this.$refs.attach_file.files.length > 5) {
                this.fileerror = 'Maximum 5 files are allowed';
                this.filecount = this.$refs.attach_file.files.length;
            } else {
                this.fileerror = '';
                this.attachfile1 = null;
                this.attachfile2 = null;
                this.attachfile3 = null;
                this.attachfile4 = null;
                this.attachfile5 = null;
                this.attachfileURL1 = null;
                this.attachfileURL2 = null;
                this.attachfileURL3 = null;
                this.attachfileURL4 = null;
                this.attachfileURL5 = null;
                let len = this.$refs.attach_file.files.length;
                if (len === 1) {
                    this.attachfile1 = this.$refs.attach_file.files[0];
                } else if (len === 2) {
                    this.attachfile1 = this.$refs.attach_file.files[0];
                    this.attachfile2 = this.$refs.attach_file.files[1];
                } else if (len === 3) {
                    this.attachfile1 = this.$refs.attach_file.files[0];
                    this.attachfile2 = this.$refs.attach_file.files[1];
                    this.attachfile3 = this.$refs.attach_file.files[2];
                } else if (len === 4) {
                    this.attachfile1 = this.$refs.attach_file.files[0];
                    this.attachfile2 = this.$refs.attach_file.files[1];
                    this.attachfile3 = this.$refs.attach_file.files[2];
                    this.attachfile4 = this.$refs.attach_file.files[3];
                } else if (len === 5) {
                    this.attachfile1 = this.$refs.attach_file.files[0];
                    this.attachfile2 = this.$refs.attach_file.files[1];
                    this.attachfile3 = this.$refs.attach_file.files[2];
                    this.attachfile4 = this.$refs.attach_file.files[3];
                    this.attachfile5 = this.$refs.attach_file.files[4];
                }
                this.filecount = len;
                if (this.attachfile1 != null) {
                    this.attachfileURL1 = (window.URL || window.webkitURL).createObjectURL(this.attachfile1);
                }
                if (this.attachfile2 != null) {
                    this.attachfileURL2 = (window.URL || window.webkitURL).createObjectURL(this.attachfile2);
                }
                if (this.attachfile3 != null) {
                    this.attachfileURL3 = (window.URL || window.webkitURL).createObjectURL(this.attachfile3);
                }
                if (this.attachfile4 != null) {
                    this.attachfileURL4 = (window.URL || window.webkitURL).createObjectURL(this.attachfile4);
                }
                if (this.attachfile5 != null) {
                    this.attachfileURL5 = (window.URL || window.webkitURL).createObjectURL(this.attachfile5);
                }
            }
        },

        getTicketsByStatus(ev) {
            this.loading = true;
            this.ApiService.getTicketsByStatus(ev).then((data) => {
                if (data.status == 200) {
                    this.newTicketList = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.newTicketList = [];
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getTicketsByStatus({ page_no: this.page_no, status: 1, sa4: this.filterListing.ticketnumber, sa2: this.filterListing.client_fks, sa12: this.filterListing.createdDate });
        },
        getActiveUsers() {
            this.ApiService.getActiveUsers().then((data) => {
                if (data.status == 200) {
                    this.ActiveUserList = data.data.result;
                } else {
                    this.ActiveUserList = [];
                }
            });
        },
        sendMessage() {
            this.submitted = true;
            this.v$.$validate();
            if (!this.v$.sendmessage.$error && this.fileerror == '') {
                var formData = new FormData();
                formData.append('sa1', this.ticketHistoryData.sa1);
                formData.append('sa18', this.sendmessage.ticketstatus.value);
                formData.append('sa25', this.tentativeclosingtimeinhours);
                formData.append('sa26', this.sendmessage.thirdpartydependency);
                formData.append('sa16', this.sendmessage.ticketassignto.value);
                formData.append('sa17', this.sendmessage.ticketassignto.label);
                formData.append('sa6', this.sendmessage.tickettype.value);
                formData.append('sb5', this.sendmessage.responsemessage);
                formData.append('sa37', moment(this.sendmessage.tentativeclosingdate).format("YYYY-MM-DD"));

                if (this.attachfile1 != '' && this.attachfile1 != null) {
                    formData.append("attachfile1", this.attachfile1);
                }
                if (this.attachfile2 != '' && this.attachfile2 != null) {
                    formData.append("attachfile2", this.attachfile2);
                }
                if (this.attachfile3 != '' && this.attachfile3 != null) {
                    formData.append("attachfile3", this.attachfile3);
                }
                if (this.attachfile4 != '' && this.attachfile4 != null) {
                    formData.append("attachfile4", this.attachfile4);
                }
                if (this.attachfile5 != '' && this.attachfile5 != null) {
                    formData.append("attachfile5", this.attachfile5);
                }

                if (!this.v$.sendmessage.$error && this.fileerror == '') {
                    this.showloader = true;
                    this.ApiService.assignTicket(formData).then((data) => {
                        if (data.success == true) {
                            var successMsg = data.message;
                            this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                            setTimeout(() => {
                                this.v$.$reset();
                            }, 0);
                            this.sendmessage.ticketstatus = '';
                            this.sendmessage.tentativeclosingdate = '';
                            this.sendmessage.thirdpartydependency = '';
                            this.sendmessage.ticketassignto = '';
                            this.sendmessage.responsemessage = '';
                            this.attachfile1 = null;
                            this.attachfile2 = null;
                            this.attachfile3 = null;
                            this.attachfile4 = null;
                            this.attachfile5 = null;
                            this.attachfileURL1 = null;
                            this.attachfileURL2 = null;
                            this.attachfileURL3 = null;
                            this.attachfileURL4 = null;
                            this.attachfileURL5 = null;
                            this.showHistoryDialogStatus = false;
                            this.getTicketsByStatus({ page_no: this.page_no, status: 1 });
                        } else {
                            var errorMsg = data.message;
                            this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                            this.showloader = false;
                        }
                    });
                }
            }


        },
        showHistoryDialogOpen(e) {
            this.getActiveUsers();
            this.ticketHistoryData = e;
            this.loading = true;
            this.ApiService.getTicketsMessage({ sa1: e.sa1, sa2: e.sa2 }).then((data) => {
                if (data.status == 200) {
                    this.ticketMessage = data.data.ticket_messages;
                    if (data.data.sa6 == 1) {
                        this.sendmessage.tickettype = { value: "1", label: "Technical" };
                    }
                    if (data.data.sa6 == 2) {
                        this.sendmessage.tickettype = { value: "2", label: "Logical (Navigational)" };
                    }
                    if (data.data.sa26 == 1) {
                        this.sendmessage.thirdpartydependency = 1;
                    }
                    if (data.data.sa26 == 0) {
                        this.sendmessage.thirdpartydependency = 0;
                    }
                    if (data.data.sa16 == null) {
                        let capitalizedFullName = this.user_full_name
                            .split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ');
                        this.sendmessage.ticketassignto = { value: this.user_id, label: capitalizedFullName };
                    }

                    this.showHistoryDialogStatus = true;
                } else {
                    this.ticketMessage = null;
                }
                this.loading = false;
            });
            this.submitted = false;
            this.attachfile1 = null;
            this.attachfile2 = null;
            this.attachfile3 = null;
            this.attachfile4 = null;
            this.attachfile5 = null;
            this.attachfileURL1 = null;
            this.attachfileURL2 = null;
            this.attachfileURL3 = null;
            this.attachfileURL4 = null;
            this.attachfileURL5 = null;
            this.filecount = '';
        },

        filterlisting() {
            this.getClientList();
            this.filterListingDialog = true;
        },
        getClientList() {
            this.ApiService.getClientListOnly().then((data) => {
                if (data.status == 200) {
                    this.clientList = data.data;
                } else {
                    this.clientList = null;
                }
            });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        getFilteredData(client_fks, ticketnumber, createdDate) {
            this.loading = true;
            this.filterListing.client_fks = client_fks;
            this.filterListing.ticketnumber = ticketnumber;
            if (createdDate != "" && createdDate != null) {
                this.filterListing.createdDate = moment(createdDate).format("YYYY-MM-DD");
            }
            this.getTicketsByStatus({
                page_no: this.page_no, status: 1, sa4: ticketnumber, sa2: client_fks, sa12: this.filterListing.createdDate
            });
            this.filterListingDialog = false;
        },
        reSetFilteredData() {
            this.filterListing.client_fks = '';
            this.filterListing.ticketnumber = '';
            this.filterListing.createdDate = '';
            this.filterListingDialog = false;
        },
        triggerCallToMeraNetaClients(data) {
            this.isDisabled = true;
            this.ApiService.triggercalltomeranetaclients({ mobile_number: data.mbk6, sa2: data.sa2, sa4: data.sa4, sa30: data.sa30, sa31: data.sa31 }).then(
                (items) => {
                    if (items.success === true) {
                        this.isDisabled = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        this.isDisabled = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                }
            );
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return '-';
            }
        },
        loginAsClientRequest() {
            this.id = this.client_id;
            this.meraneta_url = this.loginsystemurl;
            // this.clientbmsurl = 'http://' + this.loginsystemurl + '/clientLogin';
            // this.clientbmsurl = "https://testbjpka.nextedgecivictech.in/dashboard";
            // window.open(this.clientbmsurl, "_blank");
            this.isSubmitted = true;
            this.v$.$validate();
            var fields = {};
            fields["client_id"] = this.client_id;
            //fields["meraneta_url"] = this.meraneta_url;
            fields["userId"] = this.clientEmployees.value;
            fields["userlabel"] = this.clientEmployees.label;
            this.showLoader = true;
            this.ApiService.getLoginAsClient(fields).then((items) => {
                if (items.status == 200) {
                    this.showLoader = false;
                    var successMsg = items.message;
                    if (items.url) {
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        window.open(items.url, '_blank');
                    }
                } else {
                    this.showLoader = false;
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },

    },
};
</script>

<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}

.custom-scroll {
    background: #e4ded9;
    border-radius: 5px;
    padding: 0.25em 0.5rem;
}

.msg-left {
    float: left;
    width: 80%;
    padding: 5px;
}

.msg-right {
    float: right;
    width: 80%;
    padding: 5px;
}

.arrow {
    width: 12px;
    height: 20px;
    overflow: hidden;
    position: relative;
    float: left;
    top: 6px;
    right: -1px;
}

.arrow .outer {
    width: 0;
    height: 0;
    border-right: 20px solid #000000;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
}

.arrow .inner {
    width: 0;
    height: 0;
    border-right: 20px solid #ffffff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 0;
    left: 2px;
}

.message-body {
    float: left;
    width: 80%;
    height: auto;
    border: 1px solid #CCC;
    background-color: #ffffff;
    border: 1px solid #000000;
    padding: 6px 8px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.message-body p {
    margin: 0;
}

.image {
    float: left;
}

.msg-datetime-left {
    position: relative;
    float: right;
    margin-right: 100px;
}

.msg-datetime-right {
    position: relative;
    float: right;
    margin-right: 100px;
}


.arrow-right {
    width: 12px;
    height: 20px;
    overflow: hidden;
    position: relative;
    float: left;
    top: 6px;
    left: -1px;
}

.arrow-right .outer-right {
    width: 0;
    height: 0;
    border-left: 20px solid #000000;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 0;
    right: 0;
}

.arrow-right .inner-right {
    width: 0;
    height: 0;
    border-left: 20px solid #ffffff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 0;
    right: 2px;
}

.send-btn {
    width: 90px;
    left: 425px;
    right: 425px
}

.custom-btn-class {
    margin-left: 45px;
    margin-bottom: -8px;
}

.custom-status {
    margin-left: 75px;
}

.custom-heading-ticket-comm {
    margin-bottom: 20px;
    color: #3109a7;
}

.attachment {
    float: left;
    margin-top: 5px;
}

.attach-file {
    float: left;
    padding: 1px;
}
</style>

<style lang="scss" scoped>
/* Responsive */
.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.p-button-xs {
    width: 1rem;
}

.p-button.p-button-xs {
    padding: 0.925rem 0.925rem;
}

.p-button.p-button-xs .p-button-icon {
    font-size: 0.675rem;
}

.p-button.p-button-xs.p-button-icon-only.p-button-rounded {
    height: 1rem;
}

::v-deep(.p-progressbar) {
    height: 1rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #689f38;
    }

    .p-progressbar-label {
        display: block;
        color: #ffffff;
        line-height: 13px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-customers {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr {
                border-bottom: 1px solid #dee2e6;

                >td {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0 none !important;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: 0.4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -0.4rem 1rem -0.4rem -0.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}

.img-sepration {
    margin-right: 10px;
    display: inline-flex;
}

.ticketopenscreen {
    border: 2px solid #625e5e;
    padding: 4px;
    background: #eee8e8;
}
</style>